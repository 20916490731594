import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
      }
    }
  }
`;

const Seo = ({ data }) => {
  const { site } = useStaticQuery(query);
  const { pathname } = useLocation();
  const { defaultTitle, defaultDescription, siteUrl } = site.siteMetadata;
  const seo = {
    title: data?.title || defaultTitle,
    description: data?.description || defaultDescription,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet title={seo?.title} htmlAttributes={{ lang: "en-US" }}>
      <meta name="description" content={seo.description} />
      <meta name="theme-color" content="#ffffff" />
      <meta name="msapplication-navbutton-color" content="#ffffff" />
      <meta
        name="google-site-verification"
        content="9NN5bJJ6EhoZFFG2xy_xIyv9zHYAeYL0Ihq05rCurk0"
      />
      <meta name="apple-mobile-web-app-status-bar-style" content="#ffffff" />
      {seo?.url && <meta property="og:url" content={seo.url} />}
      {seo?.title && <meta property="og:title" content={seo.title} />}
      {seo?.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta property="og:site_name" content="Tranquilobay" />
      <meta name="twitter:card" content="summary_large_image" />
      {seo?.title && <meta name="twitter:title" content={seo.title} />}
      {seo?.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {data?.keywords?.length > 0 && (
        <meta name="keywords" content={data.keywords.join(",")} />
      )}
      {data?.ldSchema?.length > 0 &&
        data.ldSchema.map((item, index) => (
          <script type="application/ld+json" key={index}>
            {JSON.parse(item)}
          </script>
        ))}
    </Helmet>
  );
};

export default Seo;
